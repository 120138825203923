// @ts-nocheck
import { IModules } from "../pages/moduleCreator/models/Modules.interfaces";

export const mockPermissions: {
    status: boolean;
    data: {
        id: string;
        userId: number;
        roleId: number;
        permissions: IModules[]
        home: string
    };
} = {
    "data": {
        "id": 30001,
        "role": "Admin",
        "role_slug": "ADMIN",
        "role_type": "global",
        "home": "dashboard",
        "deleted_at": null,
        "created_at": null,
        "updated_at": null,
        "permissions": [
            {
                "id": 5,
                "type": "header",
                "path": null,
                "title": "Main",
                "folder": null,
                "element": null,
                "parentId": null,
                "generateCRUD": true,
                "level": "1",
                "menu_order": null,
                "actions": [],
                "sidebarOptions": {
                    "module_id": 5,
                    "hidden": true,
                    "icon": "document",
                    "iconType": "duotone"
                }
            },
            {
                "id": 6,
                "type": "item",
                "path": "dashboard",
                "title": "Dashboard",
                "folder": "adminDashboard",
                "element": "AdminDashboard",
                "parentId": "5",
                "generateCRUD": false,
                "level": "2",
                "menu_order": null,
                "sidebarOptions": {
                    "module_id": 6,
                    "hidden": false,
                    "icon": "abstract-30",
                    "iconType": "outline"
                },
                "actions": [
                    {
                        "title": "Dashboard",
                        "key": "dashboard",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Samples",
                        "key": "samples",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": "SampleReport",
                        "path": "reports\/samples"
                    },
                    {
                        "title": "Today order",
                        "key": "today-order",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": "TodayOrder",
                        "path": null
                    },
                    {
                        "title": "Today deposit",
                        "key": "today-deposit",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": "TodayDeposit",
                        "path": null
                    },
                    {
                        "title": "Sales trend",
                        "key": "sales-trend",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": "SalesTrend",
                        "path": null
                    }
                ]
            },
            {
                "id": 7,
                "type": "item",
                "path": "hubs-distributors",
                "title": "Hubs\/ distributors",
                "folder": "hubsDistrubutors",
                "element": "HubsDistrubutors",
                "parentId": "5",
                "generateCRUD": true,
                "level": "2",
                "menu_order": null,
                "sidebarOptions": {
                    "module_id": 7,
                    "hidden": false,
                    "icon": "people",
                    "iconType": "outline"
                },
                "actions": [
                    {
                        "title": "Add Hubs\/ distrubutors",
                        "key": "create",
                        "value": true,
                        "type": "global",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "View Hubs\/ distrubutors",
                        "key": "read",
                        "value": true,
                        "type": "inline",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Edit Hubs\/ distrubutors",
                        "key": "update",
                        "value": true,
                        "type": "inline",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Delete Hubs\/ distrubutors",
                        "key": "delete",
                        "value": true,
                        "type": "inline",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Add hub locations",
                        "key": "add-hub-locations",
                        "value": true,
                        "type": "inline",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    }
                ]
            },
            {
                "id": 8,
                "type": "item",
                "path": "customers",
                "title": "Customers",
                "folder": "Customers",
                "element": "Customers",
                "parentId": "5",
                "generateCRUD": true,
                "level": "2",
                "menu_order": null,
                "sidebarOptions": {
                    "module_id": 8,
                    "hidden": false,
                    "icon": "user-square",
                    "iconType": "outline"
                },
                "actions": [
                    {
                        "title": "Add Customers",
                        "key": "create",
                        "value": true,
                        "type": "global",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "View Customers",
                        "key": "read",
                        "value": true,
                        "type": "inline",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Edit Customers",
                        "key": "update",
                        "value": true,
                        "type": "inline",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Delete Customer",
                        "key": "delete",
                        "value": true,
                        "type": "inline",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Customer list export",
                        "key": "customer-list-export",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Customer stats",
                        "key": "customer-stats",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    }
                ]
            },
            {
                "id": 10,
                "type": "item",
                "path": "customers\/view-customer",
                "title": "View Customers",
                "folder": "Customers\/views",
                "element": "ViewCustomers",
                "parentId": "5",
                "generateCRUD": false,
                "level": "2",
                "menu_order": null,
                "sidebarOptions": {
                    "module_id": 10,
                    "hidden": true,
                    "icon": "abstract-33",
                    "iconType": "duotone"
                },
                "actions": [
                    {
                        "title": "Update",
                        "key": "update",
                        "value": true,
                        "type": "inline",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Update Customers",
                        "key": "update-customers",
                        "value": true,
                        "type": "inline",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Wallet display",
                        "key": "wallet-display",
                        "value": true,
                        "type": "inline",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Wallet update",
                        "key": "wallet-update",
                        "value": true,
                        "type": "inline",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Calendar",
                        "key": "calendar",
                        "value": true,
                        "type": "inline",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Credit limit display",
                        "key": "credit-limit-display",
                        "value": true,
                        "type": "inline",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Credit limit update",
                        "key": "credit-limit-update",
                        "value": true,
                        "type": "inline",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Add preference",
                        "key": "add-preference",
                        "value": true,
                        "type": "inline",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Cash collection",
                        "key": "cash-collection",
                        "value": true,
                        "type": "inline",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Send recharge link",
                        "key": "send-recharge-link",
                        "value": true,
                        "type": "inline",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Update DND",
                        "key": "update-dnd",
                        "value": true,
                        "type": "inline",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Update postpaid",
                        "key": "update-postpaid",
                        "value": true,
                        "type": "inline",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Update Inactive",
                        "key": "update-inactive",
                        "value": true,
                        "type": "inline",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Update Customer Block",
                        "key": "update-customer-block",
                        "value": true,
                        "type": "inline",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "View Customer Subscription",
                        "key": "view-customer-subscription",
                        "value": true,
                        "type": "inline",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Add Customer Subscriptions",
                        "key": "add-customer-subscriptions",
                        "value": true,
                        "type": "inline",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Customer Buy Once",
                        "key": "customer-buy-once",
                        "value": true,
                        "type": "inline",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Update Subsctipion status",
                        "key": "update-subsctipion-status",
                        "value": true,
                        "type": "inline",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Update Customer Subscription",
                        "key": "update-customer-subscription",
                        "value": true,
                        "type": "inline",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Change subcription quantity in bulk",
                        "key": "change-subcription-quantity-in-bulk",
                        "value": true,
                        "type": "inline",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Delete Customer subscription",
                        "key": "delete-customer-subscription",
                        "value": true,
                        "type": "inline",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "View Customer Order",
                        "key": "view-customer-order",
                        "value": true,
                        "type": "inline",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Add Adhoc Order",
                        "key": "add-adhoc-order",
                        "value": true,
                        "type": "inline",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Mark Order Delivered",
                        "key": "mark-order-delivered",
                        "value": true,
                        "type": "inline",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Cancel Customer Order",
                        "key": "cancel-customer-order",
                        "value": true,
                        "type": "inline",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Customer Order management",
                        "key": "customer-order-management",
                        "value": true,
                        "type": "inline",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "View Customer Wallet",
                        "key": "view-customer-wallet",
                        "value": true,
                        "type": "inline",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Customer Wallet Export",
                        "key": "customer-wallet-export",
                        "value": true,
                        "type": "inline",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "View Customer Activities",
                        "key": "view-customer-activities",
                        "value": true,
                        "type": "inline",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "View Customer Ticket",
                        "key": "view-customer-ticket",
                        "value": true,
                        "type": "inline",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Add Customer Ticket",
                        "key": "add-customer-ticket",
                        "value": true,
                        "type": "inline",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Update Customer Ticket",
                        "key": "update-customer-ticket",
                        "value": true,
                        "type": "inline",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Delete Customer Ticket",
                        "key": "delete-customer-ticket",
                        "value": true,
                        "type": "inline",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "View Customer Pause",
                        "key": "view-customer-pause",
                        "value": true,
                        "type": "inline",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Update Customer Pause",
                        "key": "update-customer-pause",
                        "value": true,
                        "type": "inline",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    }
                ]
            },
            {
                "id": 11,
                "type": "list",
                "path": "locations",
                "title": "Locations",
                "folder": null,
                "element": null,
                "parentId": "5",
                "generateCRUD": true,
                "level": "1",
                "menu_order": null,
                "actions": [],
                "sidebarOptions": {
                    "module_id": 11,
                    "hidden": false,
                    "icon": "map",
                    "iconType": "outline"
                }
            },
            {
                "id": 12,
                "type": "item",
                "path": "locations\/cities",
                "title": "Cities",
                "folder": "Locations\/Cities",
                "element": "Cities",
                "parentId": "11",
                "generateCRUD": true,
                "level": "2",
                "menu_order": null,
                "sidebarOptions": {
                    "module_id": 12,
                    "hidden": false,
                    "icon": "map",
                    "iconType": "outline"
                },
                "actions": [
                    {
                        "title": "Add City",
                        "key": "create",
                        "value": true,
                        "type": "global",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Edit City",
                        "key": "update",
                        "value": true,
                        "type": "inline",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Delete City",
                        "key": "delete",
                        "value": true,
                        "type": "inline",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    }
                ]
            },
            {
                "id": 13,
                "type": "item",
                "path": "locations\/area",
                "title": "Area",
                "folder": "Locations\/Area",
                "element": "Area",
                "parentId": "11",
                "generateCRUD": true,
                "level": "2",
                "menu_order": null,
                "sidebarOptions": {
                    "module_id": 13,
                    "hidden": false,
                    "icon": "map",
                    "iconType": "outline"
                },
                "actions": [
                    {
                        "title": "Add Area",
                        "key": "create",
                        "value": true,
                        "type": "global",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Edit Area",
                        "key": "update",
                        "value": true,
                        "type": "inline",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Delete Area",
                        "key": "delete",
                        "value": true,
                        "type": "inline",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    }
                ]
            },
            {
                "id": 14,
                "type": "item",
                "path": "locations\/sub-area",
                "title": "Sub Area",
                "folder": "Locations\/SubArea",
                "element": "SubArea",
                "parentId": "11",
                "generateCRUD": true,
                "level": "2",
                "menu_order": null,
                "sidebarOptions": {
                    "module_id": 14,
                    "hidden": false,
                    "icon": "map",
                    "iconType": "outline"
                },
                "actions": [
                    {
                        "title": "Add Sub Area",
                        "key": "create",
                        "value": true,
                        "type": "global",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "View Sub Area",
                        "key": "read",
                        "value": true,
                        "type": "inline",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Edit Sub Area",
                        "key": "update",
                        "value": true,
                        "type": "inline",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Delete Sub Area",
                        "key": "delete",
                        "value": true,
                        "type": "inline",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    }
                ]
            },
            {
                "id": 15,
                "type": "item",
                "path": "locations\/delivery-user",
                "title": "Delivery user",
                "folder": "Locations\/DeliveryUsers",
                "element": "DeliveryUsers",
                "parentId": "11",
                "generateCRUD": true,
                "level": "2",
                "menu_order": null,
                "sidebarOptions": {
                    "module_id": 15,
                    "hidden": false,
                    "icon": "people",
                    "iconType": "outline"
                },
                "actions": [
                    {
                        "title": "Add delivery user",
                        "key": "create",
                        "value": true,
                        "type": "global",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "View delivery user",
                        "key": "read",
                        "value": true,
                        "type": "inline",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Update delivery user",
                        "key": "update",
                        "value": true,
                        "type": "inline",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Delete delivery user",
                        "key": "delete",
                        "value": true,
                        "type": "inline",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "transfer-locations",
                        "key": "transfer-locations",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "transfer-current-order",
                        "key": "transfer-current-order",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "map-locations",
                        "key": "map-locations",
                        "value": true,
                        "type": "inline",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "delivery-user-export",
                        "key": "delivery-user-export",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    }
                ]
            },
            {
                "id": 16,
                "type": "list",
                "path": "products",
                "title": "Products",
                "folder": null,
                "element": null,
                "parentId": "5",
                "generateCRUD": true,
                "level": "1",
                "menu_order": null,
                "actions": [],
                "sidebarOptions": {
                    "module_id": 16,
                    "hidden": false,
                    "icon": "cube-2",
                    "iconType": "outline"
                }
            },
            {
                "id": 17,
                "type": "item",
                "path": "products\/brands",
                "title": "Brands",
                "folder": "Products\/Brands",
                "element": "Brands",
                "parentId": "16",
                "generateCRUD": true,
                "level": "2",
                "menu_order": null,
                "sidebarOptions": {
                    "module_id": 17,
                    "hidden": false,
                    "icon": "text-bold",
                    "iconType": "outline"
                },
                "actions": [
                    {
                        "title": "Create Brands",
                        "key": "create",
                        "value": true,
                        "type": "global",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Read Brands",
                        "key": "read",
                        "value": true,
                        "type": "inline",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Update Brands",
                        "key": "update",
                        "value": true,
                        "type": "inline",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Delete Brands",
                        "key": "delete",
                        "value": true,
                        "type": "inline",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    }
                ]
            },
            {
                "id": 18,
                "type": "item",
                "path": "products\/product-categories",
                "title": "Product categories",
                "folder": "Products\/ProductCategories",
                "element": "ProductCategories",
                "parentId": "16",
                "generateCRUD": true,
                "level": "2",
                "menu_order": null,
                "sidebarOptions": {
                    "module_id": 18,
                    "hidden": false,
                    "icon": "burger-menu-2",
                    "iconType": "outline"
                },
                "actions": [
                    {
                        "title": "Add Product categories",
                        "key": "create",
                        "value": true,
                        "type": "global",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "View Product categories",
                        "key": "read",
                        "value": true,
                        "type": "inline",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Update Product categories",
                        "key": "update",
                        "value": true,
                        "type": "inline",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Delete Product categories",
                        "key": "delete",
                        "value": true,
                        "type": "inline",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    }
                ]
            },
            {
                "id": 19,
                "type": "item",
                "path": "products\/products",
                "title": "Products",
                "folder": "Products\/Products",
                "element": "Products",
                "parentId": "16",
                "generateCRUD": true,
                "level": "2",
                "menu_order": null,
                "sidebarOptions": {
                    "module_id": 19,
                    "hidden": false,
                    "icon": "parcel",
                    "iconType": "outline"
                },
                "actions": [
                    {
                        "title": "Add Products",
                        "key": "create",
                        "value": true,
                        "type": "global",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "View Product",
                        "key": "read",
                        "value": true,
                        "type": "inline",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Edit Products",
                        "key": "update",
                        "value": true,
                        "type": "inline",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Delete Products",
                        "key": "delete",
                        "value": true,
                        "type": "inline",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Add Package Images",
                        "key": "add-images",
                        "value": true,
                        "type": "inline",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Add Package",
                        "key": "create-package",
                        "value": true,
                        "type": "inline",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Update package",
                        "key": "update-package",
                        "value": true,
                        "type": "inline",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Delete package",
                        "key": "delete-package",
                        "value": true,
                        "type": "inline",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    }
                ]
            },
            {
                "id": 20,
                "type": "item",
                "path": "products\/packages",
                "title": "Packages",
                "folder": "Products\/Packages",
                "element": "Packages",
                "parentId": "16",
                "generateCRUD": true,
                "level": "2",
                "menu_order": null,
                "sidebarOptions": {
                    "module_id": 20,
                    "hidden": false,
                    "icon": "parcel",
                    "iconType": "outline"
                },
                "actions": [
                    {
                        "title": "Add Package",
                        "key": "create",
                        "value": true,
                        "type": "global",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "View Package",
                        "key": "read",
                        "value": true,
                        "type": "inline",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Edit Package",
                        "key": "update",
                        "value": true,
                        "type": "inline",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Delete Package",
                        "key": "delete",
                        "value": true,
                        "type": "inline",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Add Images",
                        "key": "add-images",
                        "value": true,
                        "type": "inline",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Add Sample",
                        "key": "add-sample",
                        "value": true,
                        "type": "inline",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    }
                ]
            },
            {
                "id": 10002,
                "type": "item",
                "path": "products\/combo-and-offers",
                "title": "Combo and offers",
                "folder": "Products\/ComboPackages",
                "element": "ComboPackages",
                "parentId": "16",
                "generateCRUD": true,
                "level": "2",
                "menu_order": null,
                "sidebarOptions": {
                    "module_id": 10002,
                    "hidden": false,
                    "icon": "parcel",
                    "iconType": "outline"
                },
                "actions": [
                    {
                        "title": "Assign Products",
                        "key": "create",
                        "value": true,
                        "type": "global",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Update combo package",
                        "key": "update",
                        "value": true,
                        "type": "inline",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Delete combo package",
                        "key": "delete",
                        "value": true,
                        "type": "inline",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    }
                ]
            },
            {
                "id": 10003,
                "type": "item",
                "path": "products\/banners",
                "title": "Banners",
                "folder": "Products\/Banners",
                "element": "Banners",
                "parentId": "16",
                "generateCRUD": true,
                "level": "2",
                "menu_order": null,
                "sidebarOptions": {
                    "module_id": 10003,
                    "hidden": false,
                    "icon": "slider-vertical",
                    "iconType": "outline"
                },
                "actions": [
                    {
                        "title": "Add Banners",
                        "key": "create",
                        "value": true,
                        "type": "global",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Update Banners",
                        "key": "update",
                        "value": true,
                        "type": "inline",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Delete Banners",
                        "key": "delete",
                        "value": true,
                        "type": "inline",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    }
                ]
            },
            {
                "id": 10004,
                "type": "item",
                "path": "products\/product-package-sorting",
                "title": "Product package sorting",
                "folder": "Products\/ProductPackageSorting",
                "element": "ProductPackageSorting",
                "parentId": "16",
                "generateCRUD": false,
                "level": "2",
                "menu_order": null,
                "sidebarOptions": {
                    "module_id": 10004,
                    "hidden": false,
                    "icon": "document",
                    "iconType": "outline"
                },
                "actions": [
                    {
                        "title": "Update sort order",
                        "key": "update-sort-order",
                        "value": true,
                        "type": "inline",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    }
                ]
            },
            {
                "id": 10005,
                "type": "item",
                "path": "reports",
                "title": "Reports",
                "folder": "Reports",
                "element": "Reports",
                "parentId": "5",
                "generateCRUD": false,
                "level": "2",
                "menu_order": null,
                "sidebarOptions": {
                    "module_id": 10005,
                    "hidden": false,
                    "icon": "tablet-text-down",
                    "iconType": "outline"
                },
                "actions": [
                    {
                        "title": "Auto Pause Low Wallet",
                        "key": "auto-pause-low-wallet",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": "BackOrdersReport",
                        "path": "reports\/auto-pause-low-wallet"
                    },
                    {
                        "title": "Auto pause Low Wallet Export",
                        "key": "auto-pause-low-wallet-export",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Sales report",
                        "key": "sales-report",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": "CustomerSalesReport",
                        "path": "reports\/customer-sales-report"
                    },
                    {
                        "title": "Sales report export",
                        "key": "sales-report-export",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Canceled orders report",
                        "key": "canceled-orders-report",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": "CancelOrderReport",
                        "path": "reports\/cancel-order-report"
                    },
                    {
                        "title": "Canceled orders report export",
                        "key": "canceled-orders-report-export",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Cumulative sales report",
                        "key": "cumulative-sales-report",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": "CumulativeSalesReport",
                        "path": "reports\/cumulative-sales-report"
                    },
                    {
                        "title": "Cumulative sales report export",
                        "key": "cumulative-sales-report-export",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Order sheet",
                        "key": "order-sheet",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": "OrderSheetReport",
                        "path": "reports\/order-sheet-report"
                    },
                    {
                        "title": "Order sheet export",
                        "key": "order-sheet-export",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Order sorting",
                        "key": "order-sorting",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": "OrderSortingReport",
                        "path": "reports\/order-sorting-report"
                    },
                    {
                        "title": "Wallet report",
                        "key": "wallet-report",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": "WalletReport",
                        "path": "reports\/wallet-report"
                    },
                    {
                        "title": "Wallet report export",
                        "key": "wallet-report-export",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Future order",
                        "key": "future-order",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": "FutureOrdersReport",
                        "path": "reports\/future-orders-report"
                    },
                    {
                        "title": "Future order export",
                        "key": "future-order-export",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Hub delivery report",
                        "key": "hub-delivery-report",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": "HubDeliveryReport",
                        "path": "reports\/hub-delivery-report"
                    },
                    {
                        "title": "Hub delivery report export",
                        "key": "hub-delivery-report-export",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Subscription report",
                        "key": "subscription-report",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": "SubscriptionReport",
                        "path": "reports\/subscription-report"
                    },
                    {
                        "title": "Subscription report export",
                        "key": "subscription-report-export",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Activity logs",
                        "key": "activity-logs",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": "ActivityLogsReport",
                        "path": "reports\/activity-logs-report"
                    },
                    {
                        "title": "Activity logs export",
                        "key": "activity-logs-export",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Transaction report",
                        "key": "transaction-report",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": "TransactionsReport",
                        "path": "reports\/transactions-report"
                    },
                    {
                        "title": "Transaction report export",
                        "key": "transaction-report-export",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Low credit report",
                        "key": "low-credit-report",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": "LowCreditsReport",
                        "path": "reports\/low-credits-report"
                    },
                    {
                        "title": "Low credit report export",
                        "key": "low-credit-report-export",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Low credit send recharge link",
                        "key": "low-credit-send-recharge-link",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Customer life cycle report",
                        "key": "customer-life-cycle-report",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": "CustomerLifeCycleReport",
                        "path": "reports\/customer-lifecycle-report"
                    },
                    {
                        "title": "Customer life cycle report export",
                        "key": "customer-life-cycle-report-export",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Return report",
                        "key": "return-report",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": "ReturnsReport",
                        "path": "reports\/returns-report"
                    },
                    {
                        "title": "Return report export",
                        "key": "return-report-export",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "New location request",
                        "key": "new-location-request",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": "NewLocationRequestReport",
                        "path": "reports\/new-location-request-report"
                    },
                    {
                        "title": "New location request export",
                        "key": "new-location-request-export",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Pause report",
                        "key": "pause-report",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": "VacationsReport",
                        "path": "reports\/pause-report"
                    },
                    {
                        "title": "Pause report export",
                        "key": "pause-report-export",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Predictive analysis report",
                        "key": "predictive-analysis-report",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": "PredictiveAnalysisReport",
                        "path": "reports\/predictive-analysis-report"
                    },
                    {
                        "title": "Predictive analysis report export",
                        "key": "predictive-analysis-report-export",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Product package sorting",
                        "key": "product-package-sorting",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": "ProductPackageSortingReport",
                        "path": "reports\/product-package-sorting-report"
                    },
                    {
                        "title": "Update product package sorting",
                        "key": "update-product-package-sorting",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Delivery executive referral report",
                        "key": "delivery-executive-referral-report",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": "DeliveryExecutiveReferralReport",
                        "path": "reports\/delivery-executive-referral-report"
                    },
                    {
                        "title": "Delivery executive referral report export",
                        "key": "delivery-executive-referral-report-export",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Customer pause report",
                        "key": "customer-pause-report",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": "CustomerVacationsReport",
                        "path": "reports\/customer-pause-report"
                    },
                    {
                        "title": "Order report",
                        "key": "order-report",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": "OrderReport",
                        "path": "reports\/order-report"
                    },
                    {
                        "title": "Order report Export",
                        "key": "order-report-export",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Cash collection report",
                        "key": "cash-collection-report",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": "CashCollectionReport",
                        "path": "reports\/cash-collection-report"
                    },
                    {
                        "title": "Cash collection report export",
                        "key": "cash-collection-report-export",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Customer statement report",
                        "key": "customer-statement-report",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": "CustomerStatementReport",
                        "path": "reports\/customer-statement-report"
                    },
                    {
                        "title": "Customer statement report export",
                        "key": "customer-statement-report-export",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Customer Wallet Report",
                        "key": "customer-wallet-report",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": "CustomerWalletReport",
                        "path": "reports\/customer-wallet-report"
                    },
                    {
                        "title": "Customer wallet report export",
                        "key": "customer-wallet-report-export",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Tentative demand sheet",
                        "key": "tentative-demand-sheet",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": "TentativeDemandSheetReport",
                        "path": "reports\/tentative-demand-sheet"
                    },
                    {
                        "title": "Tentative demand sheet export",
                        "key": "tentative-demand-sheet-export",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "All transactions",
                        "key": "all-transactions",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": "AllTransactionsReport",
                        "path": "reports\/all-transactions-report"
                    },
                    {
                        "title": "All transactions report export",
                        "key": "all-transactions-report-export",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Generate Invoice",
                        "key": "generate-invoice",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": "GenerateInvoiceReport",
                        "path": "reports\/generate-invoice-report"
                    },
                    {
                        "title": "Generate invoice export",
                        "key": "generate-invoice-export",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "generate statement export",
                        "key": "generate-statement-export",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Order report mark delivered",
                        "key": "order-report-mark-delivered",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Order report mark cancelled",
                        "key": "order-report-mark-cancelled",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    }
                ]
            },
            {
                "id": 10006,
                "type": "item",
                "path": "communications",
                "title": "Communications",
                "folder": "Communications",
                "element": "Communications",
                "parentId": "5",
                "generateCRUD": true,
                "level": "2",
                "menu_order": null,
                "sidebarOptions": {
                    "module_id": 10006,
                    "hidden": false,
                    "icon": "messages",
                    "iconType": "outline"
                },
                "actions": [
                    {
                        "title": "Create",
                        "key": "create",
                        "value": true,
                        "type": "global",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Read",
                        "key": "read",
                        "value": true,
                        "type": "inline",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Update",
                        "key": "update",
                        "value": true,
                        "type": "inline",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Delete",
                        "key": "delete",
                        "value": true,
                        "type": "inline",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    }
                ]
            },
            {
                "id": 10007,
                "type": "item",
                "path": "ticket",
                "title": "Ticket",
                "folder": "Ticket",
                "element": "Ticket",
                "parentId": "5",
                "generateCRUD": true,
                "level": "2",
                "menu_order": null,
                "sidebarOptions": {
                    "module_id": 10007,
                    "hidden": false,
                    "icon": "scroll",
                    "iconType": "outline"
                },
                "actions": [
                    {
                        "title": "Create",
                        "key": "create",
                        "value": true,
                        "type": "global",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Read",
                        "key": "read",
                        "value": true,
                        "type": "inline",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Update",
                        "key": "update",
                        "value": true,
                        "type": "inline",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Delete",
                        "key": "delete",
                        "value": true,
                        "type": "inline",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    }
                ]
            },
            {
                "id": 10008,
                "type": "item",
                "path": "settings",
                "title": "Settings",
                "folder": "Settings",
                "element": "Settings",
                "parentId": "5",
                "generateCRUD": false,
                "level": "2",
                "menu_order": null,
                "sidebarOptions": {
                    "module_id": 10008,
                    "hidden": true,
                    "icon": "document",
                    "iconType": "duotone"
                },
                "actions": [
                    {
                        "title": "Wallet reason",
                        "key": "wallet-reason",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": "WalletReason",
                        "path": "settings\/wallet-reason"
                    }
                ]
            },
            {
                "id": 10009,
                "type": "item",
                "path": "otp-request",
                "title": "Otp Request",
                "folder": "OtpRequest",
                "element": "OtpRequest",
                "parentId": "5",
                "generateCRUD": false,
                "level": "2",
                "menu_order": null,
                "sidebarOptions": {
                    "module_id": 10009,
                    "hidden": false,
                    "icon": "question",
                    "iconType": "outline"
                },
                "actions": [
                    {
                        "title": "Add Otp Remarks",
                        "key": "add-otp-remarks",
                        "value": true,
                        "type": "inline",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    }
                ]
            },
            {
                "id": 20008,
                "type": "item",
                "path": "users",
                "title": "Users",
                "folder": "Users",
                "element": "Users",
                "parentId": "5",
                "generateCRUD": true,
                "level": "2",
                "menu_order": null,
                "sidebarOptions": {
                    "module_id": 20008,
                    "hidden": true,
                    "icon": "user",
                    "iconType": "outline"
                },
                "actions": [
                    {
                        "title": "Create",
                        "key": "create",
                        "value": true,
                        "type": "global",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Read",
                        "key": "read",
                        "value": true,
                        "type": "inline",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Update",
                        "key": "update",
                        "value": true,
                        "type": "inline",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Delete",
                        "key": "delete",
                        "value": true,
                        "type": "inline",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Change password",
                        "key": "change-password",
                        "value": true,
                        "type": "inline",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Manage Roles",
                        "key": "manage-roles",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Hub mapping",
                        "key": "hub-mapping",
                        "value": true,
                        "type": "inline",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    }
                ]
            },
            {
                "id": 30008,
                "type": "item",
                "path": "roles",
                "title": "Roles",
                "folder": "Roles",
                "element": "Roles",
                "parentId": "5",
                "generateCRUD": true,
                "level": "2",
                "menu_order": null,
                "sidebarOptions": {
                    "module_id": 30008,
                    "hidden": true,
                    "icon": "security-user",
                    "iconType": "outline"
                },
                "actions": [
                    {
                        "title": "Create",
                        "key": "create",
                        "value": true,
                        "type": "global",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Read",
                        "key": "read",
                        "value": false,
                        "type": "inline",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Update",
                        "key": "update",
                        "value": true,
                        "type": "inline",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    },
                    {
                        "title": "Delete",
                        "key": "delete",
                        "value": true,
                        "type": "inline",
                        "isDefault": true,
                        "element": null,
                        "path": null
                    }
                ]
            },
            {
                "id": 40008,
                "type": "item",
                "path": "profile",
                "title": "Profile",
                "folder": "Profile",
                "element": "Profile",
                "parentId": "5",
                "generateCRUD": false,
                "level": "2",
                "menu_order": null,
                "sidebarOptions": {
                    "module_id": 40008,
                    "hidden": true,
                    "icon": "user",
                    "iconType": "outline"
                },
                "actions": [
                    {
                        "title": "Index",
                        "key": "index",
                        "value": true,
                        "type": "global",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    }
                ]
            },
            {
                "id": 40009,
                "type": "item",
                "path": "help",
                "title": "Help",
                "folder": "Help",
                "element": "Help",
                "parentId": "5",
                "generateCRUD": false,
                "level": "2",
                "menu_order": null,
                "sidebarOptions": {
                    "module_id": 40009,
                    "hidden": true,
                    "icon": "question-2",
                    "iconType": "outline"
                },
                "actions": [
                    {
                        "title": "Index",
                        "key": "index",
                        "value": true,
                        "type": "inline",
                        "isDefault": false,
                        "element": null,
                        "path": null
                    }
                ]
            }
        ]
    },
    "message": "",
    "status": true
}