import { IRolesAndPersmissions, IValidataOtpResponse, LoginRequest } from '../models/Auth.interface'
import { LOGIN_API, LOGIN_WITH_PHONE_API, LOGIN_WITH_VALIDATE_API, PERMISSION_API, USER_API, USER_PERMISSION_API } from '../apis'
import { api } from '../../../services/api/api'
import { User } from '../../../pages/users/models/Users.interfaces';
import { IResponse } from '../../../network/network.interfaces';
import { appNotification } from '../../../app.slice';
import { BackendAuthApi, DashBoardRelatedApi, USER_MANAGEMENT_BASE_URL } from '../../../services/api/api.constant';
import { IResetPasswordForm } from '../components/forgotPasswordStep/ResetPasswordForm';
import { ISidebarCounter } from '../../../app.interfaces';

export interface UserResponse {
    status: boolean;
    data: User
}

export const authApi = api
    .injectEndpoints({
        endpoints: builder => ({
            getProfile: builder.query<UserResponse, { user_id: string }>({
                query: ({ user_id }) => ({
                    url: `${import.meta.env.VITE_APP_PROD_API_URL}${USER_API}/${user_id}`,
                }),
                providesTags: (result) => [{ type: "Auth", id: result?.data?.token }]
            }),
            login: builder.mutation<IResponse<any>, LoginRequest>({
                query: (credentials) => ({
                    url: `${BackendAuthApi}${LOGIN_API}`,
                    method: 'POST',
                    body: credentials,
                }),
                invalidatesTags: (result) => [{ type: "Auth", id: result?.data?.token }, { type: "Modules", id: 'PERMISSIONS' }],
                async onQueryStarted(_, { dispatch, queryFulfilled }) {
                    try {
                        const { data } = await queryFulfilled
                        if (data?.status)
                            dispatch(appNotification({ type: 'success', title: 'Login successful', msg: data?.message || 'Login successful', notificationType: 'alert' }))
                        else
                            dispatch(appNotification({ type: 'danger', title: 'Login Failed', msg: data?.message || 'Error while login' }))
                    } catch (err) {
                        dispatch(appNotification({ type: 'danger', title: 'Login Failed', msg: JSON.stringify(err) || 'Error while login' }))
                    }
                },
            }),
            loginWithPhoneNumber: builder.mutation<IResponse<string>, { mobile_number: string }>({
                query: (credentials) => ({
                    url: LOGIN_WITH_PHONE_API,
                    method: 'POST',
                    body: credentials,
                }),


            }),
            validateOtp: builder.mutation<IResponse<IValidataOtpResponse>, { mobile_number: string, otp: number }>({
                query: (credentials) => ({
                    url: LOGIN_WITH_VALIDATE_API,
                    method: 'POST',
                    body: credentials,
                }),
                transformResponse: (result: any, meta, arg) => {
                    return { ...result, data: { ...result.data, mobile_number: arg.mobile_number } }
                },
                invalidatesTags: (result) => [{ type: "Auth", id: result?.data?.token }, { type: "Modules", id: 'PERMISSIONS' }]

            }),
            sendforgotPasswordOtp: builder.mutation<IResponse<any>, { user_id: string, }>({
                query: (payload) => ({
                    url: `${BackendAuthApi}/generateOTPForPasswordReset`,
                    method: 'POST',
                    body: payload,
                }),
                async onQueryStarted(_, { dispatch, queryFulfilled }) {
                    try {
                        const { data } = await queryFulfilled
                        if (!data?.status)
                            dispatch(appNotification({ type: 'danger', title: 'Sending Otp Failed', notificationType: 'toast', msg: data?.message || 'Error while Generating Otp. please try later' }))
                    } catch (err) {
                        dispatch(appNotification({ type: 'danger', title: 'Sending Otp Failed', notificationType: 'toast', msg: JSON.stringify(err) || 'Error while Generating Otp. please try later' }))
                    }
                },
            }),
            resetPassword: builder.mutation<IResponse<any>, IResetPasswordForm>({
                query: (payload) => ({
                    url: `${BackendAuthApi}/verifyOTPForPasswordReset`,
                    method: 'POST',
                    body: payload,
                }),
                async onQueryStarted(_, { dispatch, queryFulfilled }) {
                    try {
                        const { data } = await queryFulfilled
                        if (data?.status)
                            dispatch(appNotification({ type: 'success', title: 'Reset Successfully', msg: data?.message || 'Password has been reset successfully' }))
                        else
                            dispatch(appNotification({ type: 'danger', title: 'Reset password Failed', msg: data?.message || 'Error while reseting password' }))
                    } catch (err) {
                        dispatch(appNotification({ type: 'danger', title: 'Reset password Failed', msg: JSON.stringify(err) || 'Error while reseting password' }))
                    }
                },
            }),

            getRolesAndPermissions: builder.query<IResponse<IRolesAndPersmissions>, { userId: string }>({
                query: ({ userId }) => ({
                    // url: `${BackendAuthApi}${USER_PERMISSION_API}`,
                    url: `${USER_MANAGEMENT_BASE_URL}/modules${USER_PERMISSION_API}`,
                    method: 'GET',
                }),
                providesTags: () => [{ type: "Modules", id: 'PERMISSIONS' }]
            }),
            getSideBarCounter: builder.query<IResponse<ISidebarCounter[]>, void>({
                query: () => ({
                    // url: `${BackendAuthApi}${USER_PERMISSION_API}`,
                    url: `${DashBoardRelatedApi}/leftNavigationCounters`,
                    method: 'POST',
                    body: {}
                }),
                providesTags: (result) => [{ type: "SidebarCounter", id: 'LIST' }]
            }),
            updateProfile: builder.mutation<IResponse<any>, any>({
                query: (payload) => {
                    const formData = payload;
                    //    uncoment below for  multipart formdata
                    // formData = new FormData();
                    // Object.keys(payload).map(f => formData.append(f, payload?.[f]))
                    return ({
                        method: "PUT",
                        url: `${USER_API}/${payload.user_id}`,
                        body: formData,
                        // formdata: true, uncomment for multipart formdata
                    })
                },
                async onQueryStarted(_, { dispatch, queryFulfilled }) {
                    try {
                        const { data } = await queryFulfilled
                        if (data?.status)
                            dispatch(appNotification({ title: 'Saved', type: 'success', msg: data?.message || 'Saved successfully', notificationType: 'modal' }))
                        else
                            dispatch(appNotification({ title: 'Error', msg: data?.message || 'Error while Saving!', }))
                    } catch (err) {
                        dispatch(appNotification({ title: 'Error', msg: 'Error while Saving!', }))
                    }
                },
                invalidatesTags: (result, error, arg) => [{ type: 'Auth', id: arg.token }]
            }),

            protected: builder.mutation<{ message: string }, void>({
                query: () => 'protected',
            }),
        })
    })

export const {
    useLoginMutation,
    useProtectedMutation,
    useLoginWithPhoneNumberMutation,
    useValidateOtpMutation,
    useGetRolesAndPermissionsQuery,
    useLazyGetRolesAndPermissionsQuery,
    useUpdateProfileMutation,
    useGetProfileQuery,
    useLazyGetProfileQuery,
    useResetPasswordMutation,
    useSendforgotPasswordOtpMutation,
    useGetSideBarCounterQuery,
    useLazyGetSideBarCounterQuery
} = authApi
