// cookieUtils.js
import { Cookies } from 'react-cookie';
import { CookieSetOptions } from 'universal-cookie';

const cookies = new Cookies();

// Getter function
export const getSession = (key: string) => {
    if (!cookies.get(key))
        return
    return JSON.parse(cookies.get(key));
};


export const getToken = (key: string) => {
    if (!cookies.get(key))
        return
    return (cookies.get(key));
};

// Setter function
export const setSession = (key: string, value: any, options?: CookieSetOptions) => {
    cookies.set(key, JSON.stringify(value), options);
};

export const setToken = (key: string, value: string, options?: CookieSetOptions) => {
    cookies.set(key, (value), options);
};


// Remove function
export const removeSession = (key: string, options?: CookieSetOptions) => {
    cookies.remove(key, options);
};

export const setEncodedUrl = (url: string) => {
    try {
        if (url.indexOf('auth') === -1) {
            const encodedUrl = encodeURIComponent(url);
            sessionStorage.setItem('redirectUrl', encodedUrl);
        }
    } catch (error) {
        console.error(error);
    }
};

export const getDecodedUrl = () => {
    try {
        const encodedUrl = sessionStorage.getItem("redirectUrl");
        if (encodedUrl) {
            return decodeURIComponent(encodedUrl);
        }
        return '';
    } catch (error) {
        console.error(error);
    }

};

// Example usage:
// getCookie('myCookieName');
// setCookie('myCookieName', 'cookieValue', { path: '/', maxAge: 3600 });
