import { appNotification } from '../../../app.slice';
import { IResponse } from '../../../network/network.interfaces';
import { api } from '../../../services/api/api'
import { MasterListApi, USER_MANAGEMENT_BASE_URL } from '../../../services/api/api.constant';
export const USER_API = USER_MANAGEMENT_BASE_URL + '/users';

export const userApi = api
    .enhanceEndpoints({ addTagTypes: ['Users'] }).injectEndpoints({
        endpoints: builder => ({
            getUserOptions: builder.query<IResponse<any>, void>({
                query: () => ({
                    url: `${MasterListApi}/getPortalUserList`,
                    method: 'POST'
                })
            }),
            getUsers: builder.query<IResponse<any>, any>({
                query: (queryString?: any) => {
                    const qs = Object.keys(queryString).map((q: string) => `${q}=${queryString?.[q]}`).join('&');
                    return `${USER_API}?${qs}`
                },
                transformResponse: (response: IResponse<any>, meta, arg) => {
                    const totalPages = Math.ceil(response?.data?.total / response?.data.itemsPerPage) || 0;

                    return {
                        data: response?.data,
                        pagination: {
                            items_per_page: response?.data?.itemsPerPage,
                            total: response?.data?.total,
                            page: response?.data?.page || 1,
                            totalPages,
                            links: [...Array(totalPages)].map((_, i) => ({
                                label: `${i + 1}`,
                                active: false,
                                page: i + 1
                            }))
                        },
                        status: true
                    }
                },
                providesTags: (result, error, arg) => result ? [...result?.data?.map((user: any) => ({ type: "Users", id: user?.id })), { type: "Users", id: 'LIST' }] : [{ type: "Users", id: 'LIST' }]
            }),
            getUser: builder.query<any, any>({
                query: ({ id, ...queryString }) => ({
                    method: 'GET',
                    url: `${USER_API}/${id}?${queryString}`,
                }),
            }),
            addUser: builder.mutation<any, any>({
                query: (payload) => ({
                    url: USER_API,
                    method: 'POST',
                    body: payload
                }),
                invalidatesTags: [{ type: 'Users', id: 'LIST' }],
                async onQueryStarted(_, { dispatch, queryFulfilled }) {
                    try {
                        const { data } = await queryFulfilled
                        if (data?.status) {
                            dispatch(appNotification({ title: 'Saved', msg: data?.message || 'Saved successfully', type: 'success', notificationType: 'alert' }))
                            // if (dt)
                            //     dt.reload()
                        }
                        else
                            dispatch(appNotification({ title: 'Error', msg: data?.message || 'Error while Saving!', type: 'danger' }))
                    } catch (err: any) {

                        dispatch(appNotification({ title: 'Error', msg: err?.error?.data?.message || 'Error while Saving!', type: 'danger' }))
                    }
                },
            }),
            updateUser: builder.mutation<any, any>({
                query: (payload) => ({
                    url: `${USER_API}/${payload.id}`,
                    method: 'PUT',
                    body: payload
                }),
                invalidatesTags: (result, error, arg) => [{ type: 'Users', id: arg.id }],
                async onQueryStarted({ dt }, { dispatch, queryFulfilled }) {
                    try {
                        const { data } = await queryFulfilled
                        // if (data?.status) {
                        dispatch(appNotification({ title: 'Saved', msg: data?.message || 'Saved successfully', type: 'success', notificationType: 'alert' }))
                        // if (dt)
                        //     dt.reload()
                        // }
                        // else
                        //     dispatch(appNotification({ title: 'Error', msg: data?.message || 'Error while Saving!', type: 'danger' }))
                    } catch (err) {
                        dispatch(appNotification({ title: 'Error', msg: 'Error while Saving!', type: 'danger' }))
                    }
                },

            }),
            deleteUser: builder.mutation<any, any>({
                query: (payload) => ({
                    url: `${USER_API}/${payload.id}`,
                    method: 'DELETE',
                    body: payload
                }),
                invalidatesTags: (result, error, arg) => [{ type: 'Users', id: "LIST" }],
                async onQueryStarted({ dt }, { dispatch, queryFulfilled }) {
                    try {
                        const { data } = await queryFulfilled
                        // if (data?.status) {
                        dispatch(appNotification({ title: 'Deleted', msg: data?.message || 'Deleted successfully', type: 'danger', notificationType: 'alert' }))
                        // if (dt)
                        //     dt.reload()
                        // }
                        // else
                        //     dispatch(appNotification({ title: 'Error', msg: data?.message || 'Error while Deleting!', type: 'danger' }))
                    } catch (err) {
                        dispatch(appNotification({ title: 'Error', msg: 'Error while Deleting!', type: 'danger' }))
                    }
                },
            }),
            updateUserHubMapping: builder.mutation<IResponse<any>, { user_id: string; hub_id: number[] }>({
                query: (payload) => ({
                    url: `${USER_API}/userHubMapping/${payload?.user_id}`,
                    method: 'POST',
                    body: payload
                }),

                async onQueryStarted(_, { dispatch, queryFulfilled }) {
                    try {
                        const { data } = await queryFulfilled
                        if (data?.status) {
                            dispatch(appNotification({ title: 'Saved', msg: data?.message || 'Saved successfully', type: 'success', notificationType: 'alert' }))
                            // if (dt)
                            //     dt.reload()
                        }
                        else
                            dispatch(appNotification({ title: 'Error', msg: data?.message || 'Error while Saving!', type: 'danger' }))
                    } catch (err) {
                        dispatch(appNotification({ title: 'Error', msg: 'Error while Saving!', type: 'danger' }))
                    }
                },
                invalidatesTags: () => [{ type: 'Users', id: 'LIST' }],
            }),
            changePassword: builder.mutation<IResponse<any>, { current_password: string; new_password: string; confirm_password: string; user_id?: string; }>({
                query: (payload) => ({
                    url: `${USER_API}/changePassword`,
                    method: 'POST',
                    body: payload
                }),

                async onQueryStarted(_, { dispatch, queryFulfilled }) {
                    try {
                        const { data } = await queryFulfilled
                        if (data?.status) {
                            dispatch(appNotification({ title: 'Saved', msg: data?.message || 'Password Changed successfully', type: 'success', notificationType: 'alert' }))
                            // if (dt)
                            //     dt.reload()
                        }
                        else
                            dispatch(appNotification({ title: 'Error', msg: data?.message || 'Error while Updating!', type: 'danger' }))
                    } catch (err) {
                        dispatch(appNotification({ title: 'Error', msg: 'Error while Updating!', type: 'danger' }))
                    }
                },
                invalidatesTags: () => [{ type: 'Users', id: 'LIST' }],
            }),
        })
    })

export const { useGetUserQuery, useGetUsersQuery, useLazyGetUsersQuery, useAddUserMutation, useDeleteUserMutation, useUpdateUserMutation, useUpdateUserHubMappingMutation, useGetUserOptionsQuery, useLazyGetUserOptionsQuery, useLazyGetUserQuery, useChangePasswordMutation } = userApi
