export const BASE_URL = `${import.meta.env.VITE_APP_PROD_API_URL}/backend`

export const BACKEND_BASE_URL = `${import.meta.env.VITE_APP_PROD_BACKEND_BASE_URL}`
export const USER_MANAGEMENT_BASE_URL = `${process.env.NODE_ENV === "development"
    ? import.meta.env.VITE_APP_API_URL :
    import.meta.env.VITE_APP_USER_MANAGEMENT_API_URL}`

export const MasterListApi: string = `${BASE_URL}/MasterList`
export const BackendAuthApi: string = `${BASE_URL}/BackendAuth`
export const DashBoardRelatedApi: string = `${BASE_URL}/DashBoardRelated`
