import { lazy } from 'react';

const Error404 = lazy(() => import('../modules/errors/components/Error404'));
const Error500 = lazy(() => import('../modules/errors/components/Error500'));
const Error401 = lazy(() => import('../modules/errors/components/Error401'));
const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'));
const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'));
const Login = lazy(() => import('../modules/auth/views/Login'));
const Projects = lazy(() => import("../modules/profile/components/Projects"));
const Overview = lazy(() => import("../modules/profile/components/Overview"));
const Logout = lazy(() => import('../modules/auth/views/Logout'));
const ForgotPassword = lazy(() => import('../modules/auth/views/ForgotPassword'))
const Registration = lazy(() => import('../modules/auth/views/Registration'))
const ListModules = lazy(() => import('../pages/moduleCreator/views/ListModules'))
const Modules = lazy(() => import('../pages/Modules/views/Modules'))
const Users = lazy(() => import('../pages/users/views/Users'));
const Roles = lazy(() => import('../pages/roles/views/Roles'));
const LoginWIthPhone = lazy(() => import('../modules/auth/views/LoginWIthPhone'));
const LoadingPage = lazy(() => import('../pages/loadingPage/LoadingPage'));
const AddAdminDashboard = lazy(() => import('../pages/adminDashboard/views/AddAdminDashboard'))
const AdminDashboard = lazy(() => import('../pages/adminDashboard/views/AdminDashboard'))

//imports

export const routeComponentMap: { [x: string]: unknown } = {
	Login,
	Registration,
	ForgotPassword,
	
	Projects,
	Overview,
	Error401,
	Error404,
	Error500,
	ProfilePage,
	BuilderPageWrapper,
	Logout,
	ListModules,
	Modules,
	Users,
	Roles,
	LoginWIthPhone,
	LoadingPage,
	AddAdminDashboard,
	AdminDashboard,
	//exports

}